
export async function instantiate(imports={}, runInitializer=true) {
    const externrefBoxes = new WeakMap();
    // ref must be non-null
    function tryGetOrSetExternrefBox(ref, ifNotCached) {
        if (typeof ref !== 'object') return ifNotCached;
        const cachedBox = externrefBoxes.get(ref);
        if (cachedBox !== void 0) return cachedBox;
        externrefBoxes.set(ref, ifNotCached);
        return ifNotCached;
    }

    const _ref_QGpzLWpvZGEvY29yZQ_ = imports['@js-joda/core'];
    
    const js_code = {
        'kotlin.captureStackTrace' : () => new Error().stack,
        'kotlin.wasm.internal.throwJsError' : (message, wasmTypeName, stack) => { 
            const error = new Error();
            error.message = message;
            error.name = wasmTypeName;
            error.stack = stack;
            throw error;
             },
        'kotlin.wasm.internal.stringLength' : (x) => x.length,
        'kotlin.wasm.internal.jsExportStringToWasm' : (src, srcOffset, srcLength, dstAddr) => { 
            const mem16 = new Uint16Array(wasmExports.memory.buffer, dstAddr, srcLength);
            let arrayIndex = 0;
            let srcIndex = srcOffset;
            while (arrayIndex < srcLength) {
                mem16.set([src.charCodeAt(srcIndex)], arrayIndex);
                srcIndex++;
                arrayIndex++;
            }     
             },
        'kotlin.wasm.internal.importStringFromWasm' : (address, length, prefix) => { 
            const mem16 = new Uint16Array(wasmExports.memory.buffer, address, length);
            const str = String.fromCharCode.apply(null, mem16);
            return (prefix == null) ? str : prefix + str;
             },
        'kotlin.wasm.internal.intToExternref' : (x) => x,
        'kotlin.wasm.internal.doubleToExternref' : (x) => x,
        'kotlin.wasm.internal.externrefToBoolean' : (ref) => Boolean(ref),
        'kotlin.wasm.internal.getJsEmptyString' : () => '',
        'kotlin.wasm.internal.getJsTrue' : () => true,
        'kotlin.wasm.internal.getJsFalse' : () => false,
        'kotlin.wasm.internal.externrefToString' : (ref) => String(ref),
        'kotlin.wasm.internal.externrefEquals' : (lhs, rhs) => lhs === rhs,
        'kotlin.wasm.internal.externrefHashCode' : 
        (() => {
        const dataView = new DataView(new ArrayBuffer(8));
        function numberHashCode(obj) {
            if ((obj | 0) === obj) {
                return obj | 0;
            } else {
                dataView.setFloat64(0, obj, true);
                return (dataView.getInt32(0, true) * 31 | 0) + dataView.getInt32(4, true) | 0;
            }
        }
        
        const hashCodes = new WeakMap();
        function getObjectHashCode(obj) {
            const res = hashCodes.get(obj);
            if (res === undefined) {
                const POW_2_32 = 4294967296;
                const hash = (Math.random() * POW_2_32) | 0;
                hashCodes.set(obj, hash);
                return hash;
            }
            return res;
        }
        
        function getStringHashCode(str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                var code  = str.charCodeAt(i);
                hash  = (hash * 31 + code) | 0;
            }
            return hash;
        }
        
        return (obj) => {
            if (obj == null) {
                return 0;
            }
            switch (typeof obj) {
                case "object":
                case "function":
                    return getObjectHashCode(obj);
                case "number":
                    return numberHashCode(obj);
                case "boolean":
                    return obj ? 1231 : 1237;
                default:
                    return getStringHashCode(String(obj)); 
            }
        }
        })(),
        'kotlin.wasm.internal.isNullish' : (ref) => ref == null,
        'kotlin.wasm.internal.newJsArray' : () => [],
        'kotlin.wasm.internal.jsArrayPush' : (array, element) => { array.push(element); },
        'kotlin.wasm.internal.tryGetOrSetExternrefBox_$external_fun' : (p0, p1) => tryGetOrSetExternrefBox(p0, p1),
        'kotlin.io.printlnImpl' : (message) => console.log(message),
        'kotlin.js.jsArrayGet' : (array, index) => array[index],
        'kotlin.js.jsArraySet' : (array, index, value) => { array[index] = value },
        'kotlin.js.JsArray_$external_fun' : () => new Array(),
        'kotlin.js.length_$external_prop_getter' : (_this) => _this.length,
        'kotlin.js.JsArray_$external_class_instanceof' : (x) => x instanceof Array,
        'kotlin.js.JsBoolean_$external_class_instanceof' : (x) => typeof x === 'boolean',
        'kotlin.js.JsNumber_$external_class_instanceof' : (x) => typeof x === 'number',
        'kotlin.js.JsString_$external_class_instanceof' : (x) => typeof x === 'string',
        'kotlin.js.then_$external_fun' : (_this, p0) => _this.then(p0),
        'kotlin.js.__convertKotlinClosureToJsClosure_((Js?)->Js?)' : (f) => (p0) => wasmExports['__callFunction_((Js?)->Js?)'](f, p0),
        'kotlin.js.__convertKotlinClosureToJsClosure_(()->Unit)' : (f) => () => wasmExports['__callFunction_(()->Unit)'](f, ),
        'kotlin.random.initialSeed' : () => ((Math.random() * Math.pow(2, 32)) | 0),
        'kotlinx.browser.document_$external_prop_getter' : () => document,
        'kotlinx.browser.window_$external_prop_getter' : () => window,
        'org.w3c.dom.css.display_$external_prop_setter' : (_this, v) => _this.display = v,
        'org.w3c.dom.css.height_$external_prop_setter' : (_this, v) => _this.height = v,
        'org.w3c.dom.css.width_$external_prop_setter' : (_this, v) => _this.width = v,
        'org.w3c.dom.css.style_$external_prop_getter' : (_this) => _this.style,
        'org.w3c.dom.encryptedmedia.__convertKotlinClosureToJsClosure_((Js)->Unit)' : (f) => (p0) => wasmExports['__callFunction_((Js)->Unit)'](f, p0),
        'org.w3c.dom.URL_$external_prop_getter' : (_this) => _this.URL,
        'org.w3c.dom.title_$external_prop_setter' : (_this, v) => _this.title = v,
        'org.w3c.dom.body_$external_prop_getter' : (_this) => _this.body,
        'org.w3c.dom.close_$external_fun' : (_this, ) => _this.close(),
        'org.w3c.dom.write_$external_fun' : (_this, p0) => _this.write(...p0),
        'org.w3c.dom.getElementById_$external_fun' : (_this, p0) => _this.getElementById(p0),
        'org.w3c.dom.querySelector_$external_fun' : (_this, p0) => _this.querySelector(p0),
        'org.w3c.dom.document_$external_prop_getter' : (_this) => _this.document,
        'org.w3c.dom.open_$external_fun' : (_this, p0, p1, p2, isDefault0, isDefault1, isDefault2) => _this.open(isDefault0 ? undefined : p0, isDefault1 ? undefined : p1, isDefault2 ? undefined : p2, ),
        'org.w3c.dom.alert_$external_fun' : (_this, p0) => _this.alert(p0),
        'org.w3c.dom.clientWidth_$external_prop_getter' : (_this) => _this.clientWidth,
        'org.w3c.dom.clientHeight_$external_prop_getter' : (_this) => _this.clientHeight,
        'org.w3c.dom.innerHTML_$external_prop_setter' : (_this, v) => _this.innerHTML = v,
        'org.w3c.dom.HTMLDivElement_$external_class_instanceof' : (x) => x instanceof HTMLDivElement,
        'org.w3c.dom.HTMLTableElement_$external_class_instanceof' : (x) => x instanceof HTMLTableElement,
        'org.w3c.dom.fetch_$external_fun' : (_this, p0, p1, isDefault0) => _this.fetch(p0, isDefault0 ? undefined : p1, ),
        'org.w3c.fetch.json_$external_fun' : (_this, ) => _this.json(),
        'org.w3c.fetch.Response_$external_class_instanceof' : (x) => x instanceof Response,
        'kotlinx.datetime.internal.JSJoda.compareTo_$external_fun' : (_this, p0) => _this.compareTo(p0),
        'kotlinx.datetime.internal.JSJoda.dayOfMonth_$external_fun' : (_this, ) => _this.dayOfMonth(),
        'kotlinx.datetime.internal.JSJoda.dayOfWeek_$external_fun' : (_this, ) => _this.dayOfWeek(),
        'kotlinx.datetime.internal.JSJoda.equals_$external_fun' : (_this, p0) => _this.equals(p0),
        'kotlinx.datetime.internal.JSJoda.hashCode_$external_fun' : (_this, ) => _this.hashCode(),
        'kotlinx.datetime.internal.JSJoda.monthValue_$external_fun' : (_this, ) => _this.monthValue(),
        'kotlinx.datetime.internal.JSJoda.toEpochDay_$external_fun' : (_this, ) => _this.toEpochDay(),
        'kotlinx.datetime.internal.JSJoda.toString_$external_fun' : (_this, ) => _this.toString(),
        'kotlinx.datetime.internal.JSJoda.year_$external_fun' : (_this, ) => _this.year(),
        'kotlinx.datetime.internal.JSJoda.MIN_$external_prop_getter' : (_this) => _this.MIN,
        'kotlinx.datetime.internal.JSJoda.MAX_$external_prop_getter' : (_this) => _this.MAX,
        'kotlinx.datetime.internal.JSJoda.of_$external_fun' : (_this, p0, p1, p2) => _this.of(p0, p1, p2),
        'kotlinx.datetime.internal.JSJoda.Companion_$external_object_getInstance' : () => _ref_QGpzLWpvZGEvY29yZQ_.LocalDate,
        'kotlinx.datetime.internal.JSJoda.LocalDate_$external_class_instanceof' : (x) => x instanceof _ref_QGpzLWpvZGEvY29yZQ_.LocalDate,
        'kotlinx.datetime.internal.JSJoda.value_$external_fun' : (_this, ) => _this.value(),
        'kotlinx.datetime.internal.JSJoda.compareTo_$external_fun_1' : (_this, p0) => _this.compareTo(p0),
        'kotlinx.datetime.internal.JSJoda.equals_$external_fun_1' : (_this, p0) => _this.equals(p0),
        'kotlinx.datetime.internal.JSJoda.hashCode_$external_fun_1' : (_this, ) => _this.hashCode(),
        'kotlinx.datetime.internal.JSJoda.toLocalDate_$external_fun' : (_this, ) => _this.toLocalDate(),
        'kotlinx.datetime.internal.JSJoda.toLocalTime_$external_fun' : (_this, ) => _this.toLocalTime(),
        'kotlinx.datetime.internal.JSJoda.toString_$external_fun_1' : (_this, ) => _this.toString(),
        'kotlinx.datetime.internal.JSJoda.MIN_$external_prop_getter_1' : (_this) => _this.MIN,
        'kotlinx.datetime.internal.JSJoda.MAX_$external_prop_getter_1' : (_this) => _this.MAX,
        'kotlinx.datetime.internal.JSJoda.of_$external_fun_1' : (_this, p0, p1) => _this.of(p0, p1),
        'kotlinx.datetime.internal.JSJoda.ofInstant_$external_fun' : (_this, p0, p1) => _this.ofInstant(p0, p1),
        'kotlinx.datetime.internal.JSJoda.Companion_$external_object_getInstance_1' : () => _ref_QGpzLWpvZGEvY29yZQ_.LocalDateTime,
        'kotlinx.datetime.internal.JSJoda.LocalDateTime_$external_class_instanceof' : (x) => x instanceof _ref_QGpzLWpvZGEvY29yZQ_.LocalDateTime,
        'kotlinx.datetime.internal.JSJoda.compareTo_$external_fun_2' : (_this, p0) => _this.compareTo(p0),
        'kotlinx.datetime.internal.JSJoda.equals_$external_fun_2' : (_this, p0) => _this.equals(p0),
        'kotlinx.datetime.internal.JSJoda.hashCode_$external_fun_2' : (_this, ) => _this.hashCode(),
        'kotlinx.datetime.internal.JSJoda.hour_$external_fun' : (_this, ) => _this.hour(),
        'kotlinx.datetime.internal.JSJoda.minute_$external_fun' : (_this, ) => _this.minute(),
        'kotlinx.datetime.internal.JSJoda.nano_$external_fun' : (_this, ) => _this.nano(),
        'kotlinx.datetime.internal.JSJoda.second_$external_fun' : (_this, ) => _this.second(),
        'kotlinx.datetime.internal.JSJoda.toSecondOfDay_$external_fun' : (_this, ) => _this.toSecondOfDay(),
        'kotlinx.datetime.internal.JSJoda.toString_$external_fun_2' : (_this, ) => _this.toString(),
        'kotlinx.datetime.internal.JSJoda.MIN_$external_prop_getter_2' : (_this) => _this.MIN,
        'kotlinx.datetime.internal.JSJoda.MAX_$external_prop_getter_2' : (_this) => _this.MAX,
        'kotlinx.datetime.internal.JSJoda.of_$external_fun_2' : (_this, p0, p1, p2, p3) => _this.of(p0, p1, p2, p3),
        'kotlinx.datetime.internal.JSJoda.Companion_$external_object_getInstance_2' : () => _ref_QGpzLWpvZGEvY29yZQ_.LocalTime,
        'kotlinx.datetime.internal.JSJoda.LocalTime_$external_class_instanceof' : (x) => x instanceof _ref_QGpzLWpvZGEvY29yZQ_.LocalTime,
        'kotlinx.datetime.internal.JSJoda.DateTimeFormatterBuilder_$external_fun' : () => new _ref_QGpzLWpvZGEvY29yZQ_.DateTimeFormatterBuilder(),
        'kotlinx.datetime.internal.JSJoda.appendOffset_$external_fun' : (_this, p0, p1) => _this.appendOffset(p0, p1),
        'kotlinx.datetime.internal.JSJoda.appendOffsetId_$external_fun' : (_this, ) => _this.appendOffsetId(),
        'kotlinx.datetime.internal.JSJoda.parseCaseInsensitive_$external_fun' : (_this, ) => _this.parseCaseInsensitive(),
        'kotlinx.datetime.internal.JSJoda.toFormatter_$external_fun' : (_this, p0) => _this.toFormatter(p0),
        'kotlinx.datetime.internal.JSJoda.STRICT_$external_prop_getter' : (_this) => _this.STRICT,
        'kotlinx.datetime.internal.JSJoda.Companion_$external_object_getInstance_3' : () => _ref_QGpzLWpvZGEvY29yZQ_.ResolverStyle,
        'kotlinx.datetime.internal.JSJoda.equals_$external_fun_3' : (_this, p0) => _this.equals(p0),
        'kotlinx.datetime.internal.JSJoda.hashCode_$external_fun_3' : (_this, ) => _this.hashCode(),
        'kotlinx.datetime.internal.JSJoda.toString_$external_fun_3' : (_this, ) => _this.toString(),
        'kotlinx.datetime.internal.JSJoda.totalSeconds_$external_fun' : (_this, ) => _this.totalSeconds(),
        'kotlinx.datetime.internal.JSJoda.UTC_$external_prop_getter' : (_this) => _this.UTC,
        'kotlinx.datetime.internal.JSJoda.ofHoursMinutesSeconds_$external_fun' : (_this, p0, p1, p2) => _this.ofHoursMinutesSeconds(p0, p1, p2),
        'kotlinx.datetime.internal.JSJoda.ofTotalSeconds_$external_fun' : (_this, p0) => _this.ofTotalSeconds(p0),
        'kotlinx.datetime.internal.JSJoda.Companion_$external_object_getInstance_4' : () => _ref_QGpzLWpvZGEvY29yZQ_.ZoneOffset,
        'kotlinx.datetime.internal.JSJoda.ZoneOffset_$external_class_instanceof' : (x) => x instanceof _ref_QGpzLWpvZGEvY29yZQ_.ZoneOffset,
        'kotlinx.datetime.internal.JSJoda.instant_$external_fun' : (_this, ) => _this.instant(),
        'kotlinx.datetime.internal.JSJoda.systemUTC_$external_fun' : (_this, ) => _this.systemUTC(),
        'kotlinx.datetime.internal.JSJoda.Companion_$external_object_getInstance_5' : () => _ref_QGpzLWpvZGEvY29yZQ_.Clock,
        'kotlinx.datetime.internal.JSJoda.compareTo_$external_fun_3' : (_this, p0) => _this.compareTo(p0),
        'kotlinx.datetime.internal.JSJoda.epochSecond_$external_fun' : (_this, ) => _this.epochSecond(),
        'kotlinx.datetime.internal.JSJoda.equals_$external_fun_4' : (_this, p0) => _this.equals(p0),
        'kotlinx.datetime.internal.JSJoda.hashCode_$external_fun_4' : (_this, ) => _this.hashCode(),
        'kotlinx.datetime.internal.JSJoda.nano_$external_fun_1' : (_this, ) => _this.nano(),
        'kotlinx.datetime.internal.JSJoda.toString_$external_fun_4' : (_this, ) => _this.toString(),
        'kotlinx.datetime.internal.JSJoda.MIN_$external_prop_getter_3' : (_this) => _this.MIN,
        'kotlinx.datetime.internal.JSJoda.MAX_$external_prop_getter_3' : (_this) => _this.MAX,
        'kotlinx.datetime.internal.JSJoda.ofEpochSecond_$external_fun' : (_this, p0, p1) => _this.ofEpochSecond(p0, p1),
        'kotlinx.datetime.internal.JSJoda.Companion_$external_object_getInstance_6' : () => _ref_QGpzLWpvZGEvY29yZQ_.Instant,
        'kotlinx.datetime.internal.JSJoda.Instant_$external_class_instanceof' : (x) => x instanceof _ref_QGpzLWpvZGEvY29yZQ_.Instant,
        'kotlinx.datetime.withCaughtJsException' : (body) => {
            try {
                body();
                return null;
            } catch(e) {
                return e;
            }
        },
        'kotlinx.datetime.getExceptionMessage' : (jsException) => jsException.message,
        'kotlinx.datetime.checkExceptionName' : (exception, name) => exception.name === name,
        'newObject' : () => ({}),
        'getKeys' : (owner) => Object.keys(owner),
        'getProperty' : (owner, key) => owner[key],
        'setProperty' : (owner, key, value) => owner[key] = value,
        'toJson' : (value) => JSON.stringify(value, null, 4),
        'encodeURIComponent' : (src) => encodeURIComponent(src),
        'decodeURIComponent' : (src) => decodeURIComponent(src),
        'olMap' : (mapId, locations, x, y, z) => 
            new ol.Map({
                 target: mapId,
                 layers: [
                   new ol.layer.Tile({
                     source: new ol.source.OSM()
                   }),
                   new ol.layer.Vector({
                     source: new ol.source.Vector({features: locations})
                   })
                 ],
                 view: new ol.View({
                   center: ol.proj.fromLonLat([x, y]),
                   zoom: z
                 })
            })    
        ,
        'olFeature' : (label, lon, lat) => 
            new ol.Feature({
                  geometry: new ol.geom.Point(ol.proj.fromLonLat([lon, lat])),
                  label: label
            })
        ,
        'olStyle' : (image, x, y) => 
            new ol.style.Style({
                 image: new ol.style.Icon({
                    anchor: [x, y],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'fraction',
                    src: image
                 })
              })
        ,
        'olOverlay' : (element, align, stopEvent, x, y) => 
            new ol.Overlay({
                element: element,
                positioning: align,
                stopEvent: stopEvent,
                offset: [x, y]
           })
        ,
        'olCoordinates' : (lon, lat) => new ol.geom.Point(ol.proj.fromLonLat([lon, lat])).getCoordinates(),
        'addOverlay_$external_fun' : (_this, p0) => _this.addOverlay(p0),
        'on_$external_fun' : (_this, p0, p1) => _this.on(p0, p1),
        'forEachFeatureAtPixel_$external_fun' : (_this, p0, p1) => _this.forEachFeatureAtPixel(p0, p1),
        'setStyle_$external_fun' : (_this, p0) => _this.setStyle(p0),
        'get_$external_fun' : (_this, p0) => _this.get(p0),
        'getGeometry_$external_fun' : (_this, ) => _this.getGeometry(),
        'getCoordinates_$external_fun' : (_this, ) => _this.getCoordinates(),
        'setPosition_$external_fun' : (_this, p0) => _this.setPosition(p0),
        'pixel_$external_prop_getter' : (_this) => _this.pixel
    }
    
    // Placed here to give access to it from externals (js_code)
    let wasmInstance;
    let require; 
    let wasmExports;

    const isNodeJs = (typeof process !== 'undefined') && (process.release.name === 'node');
    const isDeno = !isNodeJs && (typeof Deno !== 'undefined')
    const isStandaloneJsVM =
        !isDeno && !isNodeJs && (
            typeof d8 !== 'undefined' // V8
            || typeof inIon !== 'undefined' // SpiderMonkey
            || typeof jscOptions !== 'undefined' // JavaScriptCore
        );
    const isBrowser = !isNodeJs && !isDeno && !isStandaloneJsVM && (typeof window !== 'undefined');
    
    if (!isNodeJs && !isDeno && !isStandaloneJsVM && !isBrowser) {
      throw "Supported JS engine not detected";
    }
    
    const wasmFilePath = './kijijune-wasm-js.wasm';
    const importObject = {
        js_code,

    };
    
    try {
      if (isNodeJs) {
        const module = await import(/* webpackIgnore: true */'node:module');
        const importMeta = import.meta;
        require = module.default.createRequire(importMeta.url);
        const fs = require('fs');
        const url = require('url');
        const filepath = import.meta.resolve(wasmFilePath);
        const wasmBuffer = fs.readFileSync(url.fileURLToPath(filepath));
        const wasmModule = new WebAssembly.Module(wasmBuffer);
        wasmInstance = new WebAssembly.Instance(wasmModule, importObject);
      }
      
      if (isDeno) {
        const path = await import(/* webpackIgnore: true */'https://deno.land/std/path/mod.ts');
        const binary = Deno.readFileSync(path.fromFileUrl(import.meta.resolve(wasmFilePath)));
        const module = await WebAssembly.compile(binary);
        wasmInstance = await WebAssembly.instantiate(module, importObject);
      }
      
      if (isStandaloneJsVM) {
        const wasmBuffer = read(wasmFilePath, 'binary');
        const wasmModule = new WebAssembly.Module(wasmBuffer);
        wasmInstance = new WebAssembly.Instance(wasmModule, importObject);
      }
      
      if (isBrowser) {
        wasmInstance = (await WebAssembly.instantiateStreaming(fetch(wasmFilePath), importObject)).instance;
      }
    } catch (e) {
      if (e instanceof WebAssembly.CompileError) {
        let text = `Please make sure that your runtime environment supports the latest version of Wasm GC and Exception-Handling proposals.
For more information, see https://kotl.in/wasm-help
`;
        if (isBrowser) {
          console.error(text);
        } else {
          const t = "\n" + text;
          if (typeof console !== "undefined" && console.log !== void 0) 
            console.log(t);
          else 
            print(t);
        }
      }
      throw e;
    }
    
    wasmExports = wasmInstance.exports;
    if (runInitializer) {
        wasmExports._initialize();
    }

    return { instance: wasmInstance,  exports: wasmExports };
}
